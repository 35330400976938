/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

* {
  font-family: 'Inter';
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/font/Inter-Regular.ttf) format("truetype");
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.menu-panel {
  padding: 15px !important;
}